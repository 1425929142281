body {
  margin: 0;
  padding: 0;
  font-family: "Istok Web", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9;
  overflow-x: hidden;
  overflow-y: scroll;
  min-height: 100vh;
}

#root {
  min-height: 100vh;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #30cd70 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #30cd70;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: 'Lumberjack';
  src: local('Lumberjack'), url(./fonts/Lumberjack.woff) format('woff');
}

.sliderItem {
  width: 95% !important;
}

.slick-track {
  /* display: flex !important; */
}

.slick-slide {
  /* height: unset !important; */
  /* display: flex !important; */
}

.slick-slide > div {
  /* display: flex; */
}